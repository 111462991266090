import "aframe";
// import "aframe-animation-component";
// import "aframe-particle-system-component";
import "babel-polyfill";
import { Entity, Scene } from "aframe-react";
import React from "react";
import ReactDOM from "react-dom";

class App extends React.Component {
  render() {
    return (
      <Scene>
        <a-assets>
          <img
            id="groundTexture"
            src="https://cdn.aframe.io/a-painter/images/floor.jpg"
            alt=""
          />
          <img
            id="skyTexture"
            src="https://cdn.aframe.io/a-painter/images/sky.jpg"
            alt=""
          />
        </a-assets>

        <Entity
          primitive="a-plane"
          src="#groundTexture"
          rotation="-90 0 0"
          height="100"
          width="100"
        />
        <Entity primitive="a-light" type="ambient" color="#445451" />
        <Entity
          primitive="a-light"
          type="point"
          intensity="2"
          position="2 4 4"
        />
        <Entity
          primitive="a-sky"
          height="2048"
          radius="30"
          src="#skyTexture"
          theta-length="90"
          width="2048"
        />
        <Entity particle-system={{ preset: "snow", particleCount: 2000 }} />

        <a-box color="red" />
        <Entity
          text={{ value: "Hi i'm", align: "center", width: 1.5 }}
          position={{ x: 0, y: 2.0, z: -2 }}
        />
        <Entity
          text={{ value: "Febriansyah", align: "center", width: 1.5 }}
          position={{ x: 0, y: 1.8, z: -2 }}
        />
        <Entity
          text={{ value: "<= look other side =>", align: "center", width: 1.5 }}
          position={{ x: 0, y: 1.5, z: -2 }}
        />
        <Entity
          text={{ value: "Now I Work In", align: "center", width: 1.5 }}
          position={{ x: 2, y: 2.0, z: 0 }}
          rotation={{ x: 0, y: -90, z: 0 }}
        />
        <Entity
          text={{ value: "SEKAI.ID", align: "center", width: 1.5 }}
          position={{ x: 2, y: 1.8, z: 0 }}
          rotation={{ x: 0, y: -90, z: 0 }}
        />
        <Entity
          text={{
            value: "Odreval Limited (Kodland)",
            align: "center"
          }}
          position={{ x: 2, y: 1.6, z: 0 }}
          rotation={{ x: 0, y: -90, z: 0 }}
        />
        <Entity
          text={{
            value: "My Instagram",
            align: "center"
          }}
          position={{ x: 0, y: 2, z: 2 }}
          rotation={{ x: 0, y: -180, z: 0 }}
        />
        <Entity
          text={{
            value: "@febriansyahdotid",
            align: "center"
          }}
          position={{ x: 0, y: 1.8, z: 2 }}
          rotation={{ x: 0, y: -180, z: 0 }}
        />
        <Entity
          text={{
            value: "Public Speaking",
            align: "center"
          }}
          position={{ x: -2, y: 2.5, z: 0 }}
          rotation={{ x: 0, y: 90, z: 0 }}
        />
        <Entity
          text={{
            value: "Radio Suara Sidoarjo 'Sidoarjo Kota UMKM'",
            align: "center"
          }}
          position={{ x: -2, y: 2.2, z: 0 }}
          rotation={{ x: 0, y: 90, z: 0 }}
        />
        <Entity
          text={{
            value: "GIRAH Sukses 'Perkenalan & Membuat Website Sederhana'",
            align: "center"
          }}
          position={{ x: -2, y: 2.0, z: 0 }}
          rotation={{ x: 0, y: 90, z: 0 }}
        />
        <Entity
          text={{
            value:
              "SurabayaJS Hacktoberfest 2020 'Using git for your PWA project'",
            align: "center"
          }}
          position={{ x: -2, y: 1.8, z: 0 }}
          rotation={{ x: 0, y: 90, z: 0 }}
        />
        <Entity
          text={{
            value: "SurabayaJS Meetup #9 (Online) 'Tailwind CSS in a Nutshell'",
            align: "center"
          }}
          position={{ x: -2, y: 1.6, z: 0 }}
          rotation={{ x: 0, y: 90, z: 0 }}
        />
        <Entity
          text={{
            value: "SurabayaJS Meetup #7 'Map Based Web With Leaflet ",
            align: "center"
          }}
          position={{ x: -2, y: 1.4, z: 0 }}
          rotation={{ x: 0, y: 90, z: 0 }}
        />
      </Scene>
    );
  }
}

ReactDOM.render(<App />, document.querySelector("#sceneContainer"));
